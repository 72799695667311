// store.js
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../reducers';
import { RESET_CART } from '../actions/actions';

// Load state from local storage
const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

// Save state to local storage
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (error) {
    // Handle errors
  }
};

// Function to clear local storage
const clearLocalStorage = () => {
  try {
    localStorage.removeItem('state');
  } catch (error) {
    // Handle errors
  }
};

// Create Redux store with persisted state
const persistedState = loadState();
const store = configureStore({
  reducer: rootReducer,
  preloadedState: persistedState // Initialize store with persisted state
});

// Subscribe to store updates and save state to local storage
store.subscribe(() => {
  const state = store.getState();
  saveState(state); // Always save state on each update
  
  // Clear local storage if cartItems is empty
  if (state.cart && state.cart.cartItems && state.cart.cartItems.length === 0) {
    clearLocalStorage();
  }  
});



export default store;
