import React, { useState, useEffect, useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { Link } from 'react-router-dom';

import './Searchbar.css';

function Searchbar() {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchItems, setSearchItems] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch search results with debounce
  const fetchSearchResults = useCallback(
    debounce((query) => {
      if (query.trim() === '') {
        setSearchItems([]);
        setIsLoading(false);
        return;
      }
      setIsLoading(true);
      axios
        .get(`${process.env.REACT_APP_API_URL}/items/search?q=${query}`)
        .then((response) => {
          setSearchItems(response.data.items || []);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          setSearchItems([]);
          setIsLoading(false);
        });
    }, 300),
    []
  );

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setShowDropdown(true);
    fetchSearchResults(query);
  };

  const handleDropdownItemClick = () => {
    setSearchQuery('');
    setShowDropdown(false);
  };

  const handleBlur = () => {
    setTimeout(() => setShowDropdown(false), 100); // Delay to allow link clicks
  };

  const limitedItems = searchItems.slice(0, 10); // Limit to 10 results

  return (
    <div className="searchbar-wrapper">
      <Form className="searchbar-form">
        <InputGroup className="mb-3 searchbar-input">
          <InputGroup.Text id="basic-addon1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
            </svg>
          </InputGroup.Text>
          <Form.Control
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearchChange}
            onBlur={handleBlur}
            className="search-input"
          />
        </InputGroup>
        {showDropdown && (
          <div className="search-dropdown-wrapper">
            {isLoading && <div className="loading-spinner">Loading...</div>}
            {!isLoading && limitedItems.length === 0 && (
              <div className="no-results">No items found</div>
            )}
            {!isLoading && limitedItems.length > 0 && (
              <ul className="search-dropdown">
                {limitedItems.map((item) => (
                  <li key={item.id}>
                    <Link
                      to={`/eshop/item/${item.slug}`}
                      className="dropdown-link"
                      onClick={handleDropdownItemClick}
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </Form>
    </div>
  );
}

export default Searchbar;
