import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Button, Container, Row, Col, Figure } from 'react-bootstrap'; // Figure included
import countries from '../../data/countries.json';
import axios from 'axios';
import { resetCart, deleteFromCart } from '../../actions/actions';
import CheckoutItemTable from '../../components/eshop/CheckoutItemTable';

import "./Checkout.css";

function Checkout({ cartItems, orderUUID, resetCart, deleteFromCart }) {
  const navigate = useNavigate();
  const { orderUUID: urlOrderUUID } = useParams();

  const currentOrderUUID = orderUUID || urlOrderUUID;

  useEffect(() => {
    if (!currentOrderUUID) {
      alert('No orderUUID found. Redirecting...');
      navigate('/'); // Redirect to home or cart page
    }
  }, [currentOrderUUID, navigate]);

  const calculateTotalAmount = () => {
    if (cartItems && cartItems.length > 0) {
      return cartItems.reduce((total, item) => {
        return total + item.product.price * item.quantity;
      }, 0);
    }
    return 0;
  };

  const totalAmount = calculateTotalAmount();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '', // New phone number field
    addressLine1: '',
    addressLine2: '',
    city: '',
    province: '',
    zip: '',
    country: 'Greece',
    shippingOption: 'ELTA',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'country') {
      const newShippingOption = value === 'Greece' ? 'ELTA' : 'FedEx';
      setFormData({
        ...formData,
        [name]: value,
        shippingOption: newShippingOption,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleDeliveryOptionChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      shippingOption: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requiredFields = ['firstName', 'lastName', 'email', 'phone', 'country', 'shippingOption'];
    if (formData.shippingOption !== 'Box Now') {
      requiredFields.push('addressLine1', 'city', 'province', 'zip');
    }

    const isAllFieldsFilled = requiredFields.every((field) => formData[field]);
    if (!isAllFieldsFilled) {
      alert('Please fill in all required fields.');
      return;
    }

    const itemDetails = cartItems.map(item => ({
      name: item.product.name,
      quantity: item.quantity,
    }));

    const finalData = {
      ...formData,
      orderId: currentOrderUUID, // Add orderUUID from Redux
      totalAmount: totalAmount, // Add totalAmount from calculated cart total
      items: itemDetails, // Add item names and quantities
    };

    try {
      const orderResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/orders/createNewOrder`,
        finalData
      );

      const { orderCode } = orderResponse.data;
      const vivaOrderCode = orderCode?.vivaOrder?.orderCode;

      if (vivaOrderCode) {
        resetCart();
        const redirectUrl = `${process.env.REACT_APP_VIVA_BASE_URL}/web/checkout?ref=${vivaOrderCode}&color=${process.env.REACT_APP_VIVA_COLOR}&paymentMethod=${process.env.REACT_APP_DEFAULT_PAYMENT_METHOD}`;
        window.location.replace(redirectUrl);
      } else {
        alert('Order created but no valid payment order code received.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Failed to submit the order. Please try again.');
    }
  };

  return (
    <Container className='checkout-cont'>
      <h1 className='checkout-title'>Checkout</h1>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Form.Group controlId="firstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control type="text" name="firstName" value={formData.firstName} onChange={handleChange} required />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="lastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control type="text" name="lastName" value={formData.lastName} onChange={handleChange} required />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} required />
        </Form.Group>

        <Form.Group controlId="phone">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control type="tel" name="phone" value={formData.phone} onChange={handleChange} required />
        </Form.Group>

        {/* Conditionally hide address fields if Box Now is selected */}
        {formData.shippingOption !== 'Box Now' && (
          <>
            <Form.Group controlId="addressLine1">
              <Form.Label>Address Line 1</Form.Label>
              <Form.Control type="text" name="addressLine1" value={formData.addressLine1} onChange={handleChange} required />
            </Form.Group>
            <Form.Group controlId="addressLine2">
              <Form.Label>Address Line 2</Form.Label>
              <Form.Control type="text" name="addressLine2" value={formData.addressLine2} onChange={handleChange} />
            </Form.Group>
            <Row>
              <Col>
                <Form.Group controlId="city">
                  <Form.Label>City</Form.Label>
                  <Form.Control type="text" name="city" value={formData.city} onChange={handleChange} required />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="province">
                  <Form.Label>Province</Form.Label>
                  <Form.Control type="text" name="province" value={formData.province} onChange={handleChange} required />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="zip">
                  <Form.Label>ZIP Code</Form.Label>
                  <Form.Control type="text" name="zip" value={formData.zip} onChange={handleChange} required />
                </Form.Group>
              </Col>
            </Row>
          </>
        )}

        <Form.Group controlId="country">
          <Form.Label>Country</Form.Label>
          <Form.Control as="select" name="country" value={formData.country} onChange={handleChange} required>
            <option value="">Select Country</option>
            {countries.map((country) => (
              <option key={country.code} value={country.name}>
                {country.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        {/* Conditional Delivery Options */}
        <Form.Group>
          <Form.Label>Delivery Options</Form.Label>
          <Row>
            {formData.country === 'Greece' ? (
              <>
                <Col>
                  <Form.Check
                    type="radio"
                    name="shippingOption"
                    id="acs"
                    label={
                      <Figure>
                        <Figure.Image width={171} height={180} alt="ELTA" src="/logos/elta-logo.png" />
                        <Figure.Caption>Delivery Cost: 3.5€</Figure.Caption>
                      </Figure>
                    }
                    value="ELTA"
                    checked={formData.shippingOption === 'ELTA'}
                    onChange={handleDeliveryOptionChange}
                    required
                  />
                </Col>
              </>
            ) : (
              <Col>
                <Form.Check
                  type="radio"
                  name="shippingOption"
                  id="fedex"
                  label={
                    <Figure>
                      <Figure.Image width={171} height={180} alt="FedEx" src="/logos/fedex-logo.png" />
                      <Figure.Caption>Delivery Cost: 3.5€</Figure.Caption>
                    </Figure>
                  }
                  value="FedEx"
                  checked={formData.shippingOption === 'FedEx'}
                  onChange={handleDeliveryOptionChange}
                  required
                />
              </Col>
            )}
          </Row>
        </Form.Group>

        <Button className='order-now-button' size='lg' type="submit">
          Order Now
        </Button>
      </Form>
    </Container>
  );
}

// mapStateToProps function to get the required data from Redux store
const mapStateToProps = (state) => ({
  cartItems: state.items.cartItems,
  orderUUID: state.items.orderUUID,
});

const mapDispatchToProps = {
  resetCart,
  deleteFromCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
