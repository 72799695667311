import React, { useState } from "react";
import axios from "axios";
import { Container, Form, Button } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import "./TechnicalSupport.css";

function TechnicalSupport() {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    orderId: "",
    itemDescription: "",
    problemDescription: "",
  });
  const [captchaValue, setCaptchaValue] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptcha = (value) => {
    setCaptchaValue(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!captchaValue) {
      alert("Please complete the CAPTCHA to submit the form.");
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/technical-support`, formData);
      setFormSubmitted(true);
      alert("Form submitted successfully!");
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Failed to submit the form. Please try again.");
    }
  };

  return (
    <Container className="technical-support-container">
      <h1 className="technical-support-title">Request Technical Support</h1>
      {formSubmitted ? (
        <p>Thank you for submitting your request! We will get back to you soon.</p>
      ) : (
        <Form onSubmit={handleSubmit}>
          {/* Full Name */}
          <Form.Group controlId="fullName">
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              placeholder="Enter your full name"
              required
            />
          </Form.Group>

          {/* Email */}
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter your email"
              required
            />
          </Form.Group>

          {/* Phone */}
          <Form.Group controlId="phone">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Enter your phone number"
              required
            />
          </Form.Group>

          {/* Order/Transaction ID */}
          <Form.Group controlId="orderId">
            <Form.Label>Order/Transaction ID</Form.Label>
            <Form.Control
              type="text"
              name="orderId"
              value={formData.orderId}
              onChange={handleChange}
              placeholder="Enter your order or transaction ID"
              required
            />
          </Form.Group>

          {/* Item Name/Description */}
          <Form.Group controlId="itemDescription">
            <Form.Label>Item Name/Description</Form.Label>
            <Form.Control
              type="text"
              name="itemDescription"
              value={formData.itemDescription}
              onChange={handleChange}
              placeholder="Enter the item name or description"
              required
            />
          </Form.Group>

          {/* Problem Description */}
          <Form.Group controlId="problemDescription">
            <Form.Label>Problem Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              name="problemDescription"
              value={formData.problemDescription}
              onChange={handleChange}
              placeholder="Describe the problem you're experiencing"
              required
            />
          </Form.Group>

          {/* Google reCAPTCHA */}
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={handleCaptcha}
          />

          {/* Submit Button */}
          <Button variant="primary" type="submit" className="submit-button">
            Submit
          </Button>
        </Form>
      )}
    </Container>
  );
}

export default TechnicalSupport;
