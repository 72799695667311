import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Image from 'react-bootstrap/Image';
import { Table, Button } from 'react-bootstrap';
import QuantityControl from './QuantityControl';

import { deleteFromCart, updateQuantity } from '../../actions/actions';

import "./ShoppingCartTable.css";

function ShoppingCartTable({ cartItems, deleteFromCart, updateQuantity }) {
  const [reloadTable, setReloadTable] = useState(false);
  const [itemRemoved, setItemRemoved] = useState(false);

  // Handles Quantity Control component
  const handleQuantityChangeItem = (item, newQuantity) => {
    updateQuantity(item.product.id, newQuantity);
  };

  // Handles removing an item from the cart
  const handleRemoveItem = (itemId) => {
    deleteFromCart(itemId);
    setItemRemoved(true); // Trigger re-render after item removal
  };

  // Trigger table reload after an item is removed
  useEffect(() => {
    if (itemRemoved) {
      setReloadTable((prev) => !prev);
      setItemRemoved(false); // Reset itemRemoved state
    }
  }, [itemRemoved]);

  // Calculate total price
  const totalPrice = cartItems.reduce((total, item) => {
    return total + item.quantity * item.product.price;
  }, 0);

  return (
    <Table striped bordered hover key={reloadTable}>
      <thead>
        <tr>
          <th className="wider-column">Product</th>
          <th>Quantity</th>
          <th>Price</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {cartItems.map((item, index) => (
          <tr key={index}>
            <td className="wider-column">
              <Image
                className="cart-item-thumbnail"
                thumbnail
                src={item.product.images[0]}
              />
              {item.product.name}
            </td>
            <td className="table-quantity">
              <QuantityControl
                onQuantityChange={(newQuantity) => handleQuantityChangeItem(item, newQuantity)}
                defaultQuantity={item.quantity} // Pass item.quantity as defaultQuantity
              />
            </td>
            <td className="table-price">€{(item.quantity * item.product.price).toFixed(2)}</td>
            <td className="table-actions">
              <Button
                variant="danger"
                size="sm"
                onClick={() => handleRemoveItem(item.product.id)}
              >
                Remove
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="3" className="text-right">
            <strong>Total Amount:</strong>
          </td>
          <td>€{totalPrice.toFixed(2)}</td>
        </tr>
      </tfoot>
    </Table>
  );
}

const mapStateToProps = (state) => ({
  cartItems: state.items.cartItems,
});

const mapDispatchToProps = {
  deleteFromCart,
  updateQuantity,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCartTable);
