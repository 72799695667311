import React from 'react';
import { connect } from 'react-redux';
import Image from 'react-bootstrap/Image';
import { Table, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { deleteFromCart } from '../../actions/actions';

function CheckoutItemTable({ cartItems, deleteFromCart }) {
  const navigate = useNavigate();

  // Handle removing an item from the cart
  const handleRemoveItem = (itemId) => {
    deleteFromCart(itemId);
  };

  // Calculate total price
  const totalPrice = cartItems.reduce((total, item) => {
    return total + item.quantity * item.product.price;
  }, 0);

  return (
    <>
      <h2>Your Items</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Product</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {cartItems.map((item, index) => (
            <tr key={index}>
              <td>
                <Image
                  className="cart-item-thumbnail"
                  thumbnail
                  src={item.product.images[0]}
                  alt={item.product.name}
                  style={{ width: '50px', marginRight: '10px' }}
                />
                {item.product.name}
              </td>
              <td>{item.quantity}</td>
              <td>€{(item.quantity * item.product.price).toFixed(2)}</td>
              <td>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => handleRemoveItem(item.product.id)}
                  style={{ marginRight: '10px' }}
                >
                  X
                </Button>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => navigate('/eshop/cart')}
                >
                  Edit
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="2" className="text-right">
              <strong>Total Amount:</strong>
            </td>
            <td colSpan="2">€{totalPrice.toFixed(2)}</td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
}

// Map Redux state to props
const mapStateToProps = (state) => ({
  cartItems: state.items.cartItems,
});

// Map Redux actions to props
const mapDispatchToProps = {
  deleteFromCart,
};

// Connect the component to the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(CheckoutItemTable);
